import React from 'react'
import { Link } from 'gatsby'
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';
import clearPathname from '../utils/link'

const Do = () => {
	return (
		<L10nProvider>
			<Documentation />
		</L10nProvider>
	)
}
export const Documentation = () => {
	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<h2 className={classes.secTitle}>
					Документация
				</h2>
				<div className={classes.secLinks}>
					<Link to={clearPathname("/install")} className={classes.secLink}>
						Установка
					</Link>
					<Link to={clearPathname("/instructions")} className={classes.secLink}>
						Конфигурация
					</Link>
					<Link to={clearPathname("/plugins")} className={classes.secLink}>
						Плагины
					</Link>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Do;
